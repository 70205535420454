<template>
  <div>
    <apartment-list v-if="currentUser"></apartment-list>
  </div>
</template>

<script>
import ApartmentList from "./ApartmentList";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Apartments",
  components: {ApartmentList},
  computed: {
    ...mapGetters(["appUser", "currentUser"])
  },
  methods: {
    ...mapActions(["fetchUser", "loadPageUser"]),
  },
  async created() {
    //await this.loadPageUser();
    await this.fetchUser(this.appUser.id);
  }
}
</script>

<style scoped>

</style>